<template>
  <div class="mail-list-detail">
    <van-row>
      <van-col>
        <div class="baseInfo">
          基本信息
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <div class="customerName">
          <van-cell-group>
            <van-field readonly v-model="contractInfo.customerName" input-align="right" label="所属公司:" placeholder="请填写公司名称"/>
          </van-cell-group>
        </div>
        <div class="contacts">
          <van-cell-group>
            <van-field readonly v-model="contractInfo.contacts" input-align="right" label="联系人:" placeholder="请填写联系人姓名"/>
          </van-cell-group>
        </div>
        <div class="contactsPhone">
          <van-cell-group>
            <van-field readonly v-model="contractInfo.contactsPhone" input-align="right" label="电话:" placeholder="请填写联系人电话"/>
          </van-cell-group>
        </div>
        <div class="contactsPost">
          <van-cell-group>
            <van-field readonly v-model="contractInfo.contactsPost" input-align="right" label="联系人职务:" placeholder="请填写联系人职务"/>
          </van-cell-group>
        </div>
      </van-col>
    </van-row>
    <div class="remarks">
      <van-field
          readonly
          v-model="contractInfo.addressDetailed"
          rows="2"
          autosize
          label="地址详情:"
          type="textarea"
          show-word-limit
          input-align="right"
      />
    </div>
    <van-button v-if="this.ids == 1" type="primary" size="large" class="footer-bar">保存</van-button>
  </div>
</template>
<script>
export default {
  name: "MailListDetail",
  data() {
    return {
      contractInfo:{},
      ids: 0,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getMailInfo.json',
          {
            crmCustomerid: this.$route.params.id,
            customerName: '',
            current: 2,
            size: 1,
          }).then(res => {
        if (res.status == 200) {
          this.contractInfo = res.data[0]
          console.log(this.contractInfo)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
  }
}
</script>
<style scoped>
.mail-list-detail .total {
  background: white;
}

.mail-list-detail .baseInfo {
  font-size: 10px;
  color: #BAB8B7;
  margin-top: 10px;
  padding: 15px;
}
</style>
